.dropdown {
    @apply relative inline-block;
}

.dropdown-menu {
    min-width: 160px;
    @apply rounded-md shadow-lg bg-white dark:bg-gray-700 dark:shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none p-1.5 absolute z-30;

    &.top-start {
        @apply ltr:left-0 rtl:right-0 bottom-full origin-bottom;
    }

    &.top-center {
        @apply bottom-full left-1/2 origin-bottom;
    }

    &.top-end {
        @apply ltr:right-0 rtl:left-0 bottom-full origin-bottom;
    }

    &.bottom-start {
        @apply ltr:left-0 rtl:right-0 top-full origin-top;
    }

    &.bottom-center {
        @apply left-1/2 top-full origin-top;
    }

    &.bottom-end {
        @apply ltr:right-0 rtl:left-0 top-full origin-top;
    }

    &.middle-start-top {
        @apply left-full top-0 origin-top;
    }

    &.middle-start-bottom {
        @apply left-full bottom-0 origin-bottom;
    }

    &.middle-end-top {
        @apply right-full top-0 origin-top;
    }

    &.middle-end-bottom {
        @apply right-full bottom-0 origin-bottom;
    }
}

.dropdown-toggle-default {
    @apply cursor-pointer p-2 inline-block;

    &.dropdown-toggle-disabled {
        @apply opacity-60;
    }
}

.dropdown-submenu {
    @apply left-full top-0 origin-top;
}

.dropdown-submenu-item {
    @apply justify-between;
}

.dropdown-toggle-disabled {
    @apply cursor-not-allowed;
}